/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';

import { BookingLayout } from 'containers';

import {
  FormInput,
  FormLabel,
  BookHaka,
  BookHakaB2R,
  BookingToolbar,
  Modal,
} from 'components';
import { BookingHeader } from 'layouts';
import { PAGES } from 'config';
import { GET_DEPARTURE } from 'actions/tours';
import { mapStateToProps, FORMAT_GRAPH_DATA } from 'utilities';
import { CREATE_GUEST_USER } from 'actions/user';
import { CREATE_BOOKING } from 'actions/booking';
import { COUPONS_CLEAN_UP } from 'actions/coupons';

class BookingsPage extends PureComponent {
  constructor(props) {
    super(props);
    const TOURS = FORMAT_GRAPH_DATA(props.data.allTourPages);

    this.state = {
      selectedTour: undefined,
      selectedDeparture: undefined,
      selectedTourName: undefined,
      bornToRoam: false,
      selectedTourSlug: undefined,
      selectedDepartureName: undefined,
      tours: TOURS,
      saving: false,
      specialDeal: false,
      open: false,
      openErrorModal: false,
      availableSeats: undefined,
      errorMessage: undefined,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);

    // Remove any expired coupon codes
    COUPONS_CLEAN_UP(props.coupons.coupons, props.dispatch);
  }

  componentDidMount() {
    this.setSelected();
  }

  /**
   * Set selected tour and departure based on URL params
   */
  setSelected() {
    (async () => {
      const {
        tours,
        selectedDeparture,
        selectedTour,
        selectedTourName,
        bornToRoam,
        selectedTourSlug,
        selectedDepartureName,
        specialDeal,
      } = this.state;
      // set selected departure based on query param
      const PARSED = queryString.parse(window.location.search);
      if (!PARSED.departureId) return;
      let departureID = selectedDeparture;
      let tourID = selectedTour;
      let tourName = selectedTourName;
      let isBornToRoam = bornToRoam;
      let tourSlug = selectedTourSlug;
      let deal = specialDeal;
      let departureName = selectedDepartureName;
      let availableSeats;

      if (PARSED.departureId) {
        const GET_DEP = await GET_DEPARTURE(PARSED.departureId);
        if (GET_DEP && GET_DEP.success) {
          const DEP = GET_DEP.data;
          const TOUR = tours.find(tour => tour.api_id === DEP.tour_id);
          const START_DATE = moment(DEP.date).format('D MMM');
          const END_DATE = moment(DEP.end_date).format('D MMM, YYYY');

          if (DEP.reverse) {
            departureName = `${START_DATE} - ${END_DATE} ${TOUR.end_location} -> ${TOUR.start_location}`;
          } else {
            departureName = `${START_DATE} - ${END_DATE} ${TOUR.start_location} -> ${TOUR.end_location}`;
          }
          isBornToRoam = TOUR.is_born_to_roam_tour;
          tourName = TOUR.name;
          tourSlug = TOUR.slug;
          departureID = DEP.id;
          deal = DEP.special_deal;
          tourID = TOUR.api_id || undefined;
          availableSeats = DEP.available_seats;
        }
      }

      // set selected tour based on query param BUT not if departure param present
      if (PARSED.tourId && !PARSED.departureId) {
        tourID = tours.find(tour => tour.api_id === PARSED.tourId).api_id;
      }

      this.setState({
        selectedTour: tourID,
        selectedDeparture: departureID,
        selectedTourName: tourName,
        bornToRoam: isBornToRoam,
        selectedTourSlug: tourSlug,
        selectedDepartureName: departureName,
        specialDeal: deal,
        availableSeats
      });
    })();
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleCloseErrorModal() {
    this.setState({ openErrorModal: false });
    this.setState({ errorMessage: undefined });
  }

  /**
   * Handle form submit
   * @param e
   */
  handleFormSubmit(e) {
    e.preventDefault();
    const {
      selectedTour,
      selectedDeparture,
      selectedTourSlug,
      specialDeal,
      availableSeats,
    } = this.state;
    const {
      user,
      dispatch,
      coupons,
      commision,
    } = this.props;
    const FORM = e.currentTarget;
    const FORM_DATA = new FormData(FORM);
    if (!FORM.checkValidity()) {
      dispatch({
        type: 'MESSAGES_ADD',
        payload: {
          id: 'invalid-booking',
          content: 'Invalid booking form',
          type: 'error',
        },
      });
      return;
    }

    const NUM_TRAVELLERS = parseInt(FORM_DATA.get('NumTravellers'), 10);

    const IS_COUPLE = FORM_DATA.get('isCouple') === 'on';

    if (NUM_TRAVELLERS > availableSeats) {
      this.setState({ errorMessage: 'Unfortunately this departure date is almost full. Please select a different travel date to travel or reach out to our team at <a href="mailto: info@hakatours.com">info@hakatours.com</a>' });
      this.setState({ openErrorModal: true });

      return;
    }

    let userEmail = user.session.email;
    let userToken = user.session.token;

    this.setState({
      saving: true,
    }, async () => {
      // 1. if user not authenticated, create guest user
      if (!user.authenticated) {
        const NEW_GUEST_USER = await CREATE_GUEST_USER(dispatch);
        if (!NEW_GUEST_USER.success) {
          dispatch({
            type: 'MESSAGES_ADD',
            payload: {
              id: 'booking-guest-user',
              content: 'Failed to create guest user for booking.',
              type: 'error',
            },
          });
          return;
        }
        // user creds from response because they won't be in this context yet
        userEmail = NEW_GUEST_USER.data.session.email;
        userToken = NEW_GUEST_USER.data.session.token;
      }

      // Get a promo/coupon code if available
      let PROMO_CODE = '';

      if (!specialDeal) {
        PROMO_CODE = coupons.coupons.length ? coupons.coupons[0].code : '';
      }

      const COMMISION = commision.commision_code ? commision.commision_code : '';

      const IDEV_AFFILIATE = commision.idev_affiliate_code ? commision.idev_affiliate_code : '';

      // 2. create booking
      const NEW_BOOKING = await CREATE_BOOKING(dispatch, userToken, userEmail, {
        departure_id: selectedDeparture,
        tour_id: selectedTour,
        travellers: Array(NUM_TRAVELLERS).fill({}),
        promo_code_string: PROMO_CODE,
        commision_factory_code: COMMISION,
        affiliate_code: IDEV_AFFILIATE,
        is_couple: IS_COUPLE,
      });

      if (!NEW_BOOKING.success) {
        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'booking-create-failed',
            content: 'Failed to create new booking.',
            type: 'error',
          },
        });
        return;
      }

      this.setState({
        saving: false,
      });

      dispatch({
        type: 'RESET_COMMISION_CODE',
      });

      dispatch({
        type: 'RESET_IDEV_AFFILIATE_CODE',
      });

      if (typeof fbq !== 'undefined') {
        this.trackFacebookCheckoutEvent(NEW_BOOKING.data['bookings/booking']);
        this.trackNewZealandDotComCheckoutEvent(NEW_BOOKING.data['bookings/booking']);
      }

      // 3. send user to next step
      navigate(`${PAGES.BOOKINGS_DETAILS.PATH}?bookingId=${NEW_BOOKING.data['bookings/booking'].id}&tour=${selectedTourSlug}`);
    });
  }

  trackFacebookCheckoutEvent(booking) {
      fbq('trackSingle', '2027634157481410', 'InitiateCheckout', {
       content_type: 'destination',
       travel_start: booking.departure_date,
       travel_end: booking.end_date,
       content_ids: booking.tour_id,
       city: booking.links.fb_pixel_city,
       region: booking.links.fb_pixel_region,
       country: 'New Zealand',
       num_adults: booking.traveller_ids.length,
     });
  }

  trackNewZealandDotComCheckoutEvent(booking) {
    fbq('trackSingle', '632522023999943', 'InitiateCheckout', {
      content_type: 'destination',
      content_ids: '5519924',
      city: booking.links.fb_pixel_city,
      region: booking.links.fb_pixel_region,
      country: 'New Zealand',
    });
  }

  render() {
    const {
      selectedTourName,
      bornToRoam,
      selectedDepartureName,
      saving,
      open,
      openErrorModal,
      errorMessage
    } = this.state;

    return (
      <BookingLayout page="bookings">
        <div className="l-two-col l-two-col--large">
          <BookingHeader
            title="Book a Tour"
          />
          <div>
            <div className="u-card">
              <form action="" className="l-form" onSubmit={this.handleFormSubmit}>
                <div className="l-form__contents">
                  <h5>
                    YOUR TOUR
                  </h5>
                  {selectedTourName}
                  <br /><br />
                  <h5>
                    YOUR DEPARTURE DATES
                  </h5>
                  {selectedDepartureName}
                  <br /><br />
                  <Modal
                    open={open}
                    closeHandler={() => {
                      this.handleClose();
                    }}
                  >
                    <div className="c-activity-modal">
                      <div className="l-container" style={{ padding: '2rem' }}>
                        <div>
                          If you require more than 4 travellers please specify how many additional people you would like in the 'Special Requests' box on the next step. Our team will update your booking within 24 hours.
                        </div>
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    open={openErrorModal}
                    closeHandler={() => {
                      this.handleCloseErrorModal();
                    }}
                  >
                    <div className="c-activity-modal">
                      <div className="l-container" style={{ padding: '2rem' }}>
                        <div dangerouslySetInnerHTML={{__html: errorMessage}}></div>
                      </div>
                    </div>
                  </Modal>
                  <div className="l-form-group l-form-group--radio">
                    <h4 className="l-form-group__label">How many travellers?</h4>
                      {[1, 2, 3, 4].map(num => (
                        <div className="l-form-group__control-wrapper" key={num} onClick={() => {
                          if (num == 2){
                            document.getElementById("travellers2").classList.add("d-block")
                            document.getElementById("travellers2").classList.remove("d-none")
                            document.getElementById("isCouple-wrapper").classList.add("d-block")
                            document.getElementById("isCouple-wrapper").classList.remove("d-none")
                          } else {
                            document.getElementById("travellers2").classList.add("d-none")
                            document.getElementById("travellers2").classList.remove("d-block")
                            document.getElementById("isCouple-wrapper").classList.add("d-none")
                            document.getElementById("isCouple-wrapper").classList.remove("d-block")
                          }
                          if (num == 4){
                            document.getElementById("travellers4").classList.add("d-block")
                            document.getElementById("travellers4").classList.remove("d-none")
                          } else {
                            document.getElementById("travellers4").classList.add("d-none")
                            document.getElementById("travellers4").classList.remove("d-block")
                          }
                        }}>
                          <FormInput
                            id={`NumTravellers${num}`}
                            name="NumTravellers"
                            type="radio"
                            modifier="c-form-control--radio"
                            value={num}
                            required
                          />
                          <FormLabel inputID={`NumTravellers${num}`} title={String(num)} />
                        </div>
                      ))}
                    <br />
                    <div
                      style={{ cursor: 'pointer', width: '100%', fontSize: '0.8rem', marginTop: '0.75rem', color: '#0000CC' }}
                      onClick={this.handleOpen}
                    >
                      More than 4?
                    </div>    
                  </div>
                  <fieldset>
                    <p id="travellers4" className="w-100 d-none">
                      If you require double rooms, which include 1 large bed instead of twins, please email info@hakatours.com
                    </p>
                    <div className="l-form-group l-form-group--radio-list">
                      <p id="travellers2" className="w-100 d-none">
                        Please tick this box if you are a couple and would like a double room (one large single bed)
                      </p>
                      <div id="isCouple-wrapper" className="l-form-group__control-wrapper d-none">
                        <FormInput
                          id='isCouple'
                          name="isCouple"
                          type="checkbox"
                          modifier="c-form-control--checkbox"
                        />
                        <FormLabel inputID='isCouple' title="Is a couple?" />
                      </div>
                    </div>
                  </fieldset>
                </div>
                <BookingToolbar saving={saving}>
                  <button type="submit" className="c-button c-button--primary c-button--wide">Next</button>
                </BookingToolbar>
              </form>
            </div>
          </div>
          <div>
            {bornToRoam && (
              <BookHakaB2R />
            )}
            {!bornToRoam && (
              <BookHaka />
            )}
          </div>
        </div>
      </BookingLayout>
    );
  }
}

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
      filter: {
                hidden_category: { eq: false },
              }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          is_born_to_roam_tour
          start_location
          end_location
          number_of_days
        }
      }
    }
  }
`;

BookingsPage.propTypes = {
  data: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  coupons: PropTypes.any.isRequired,
  commision: PropTypes.any.isRequired,
};

export default connect(mapStateToProps)(BookingsPage);
